import ISupportTag from "../components/ISupportTag/ISupportTag";

export const requirementFilters = [{
  id: 1,
  text: 'Asset performance prediction'
}, {
  id: 2,
  text: 'Support chain management'
}, {
  id: 3,
  text: 'Material state management'
}, {
  id: 4,
  text: 'Whole life management'
}, {
  id: 5,
  text: 'Asset maintenance, update and upgrade'
}, {
  id: 6,
  text: 'Fleet management and forecasting'
}, {
  id: 7,
  text: 'Digitally enabled workforce'
}, {
  id: 8,
  text: 'Optimised infrastructure'
}]

export const typeFilters = [{
  id: 9,
  text: 'Sea'
}, {
  id: 10,
  text: 'Land'
}, {
  id: 11,
  text: 'Air'
}, {
  id: 12,
  text: 'Infra'
}]

const featureType = {
  integrate: 'integrate',
  acquire: 'acquire',
  configure: 'configure',
  analyse: 'analyse',
  visualise: 'visualise',
  exploit: 'exploit'
}

export const features = [{
  id: 1,
  type: featureType.integrate,
  image: require("assets/img/integrate.svg").default
},{
  id: 2,
  type: featureType.acquire,
  image: require("assets/img/acquire.svg").default
},{
  id: 3,
  type: featureType.configure,
  image: require("assets/img/configure.svg").default
},{
  id: 4,
  type: featureType.analyse,
  image: require("assets/img/analyse.svg").default
},{
  id: 5,
  type: featureType.visualise,
  image: require("assets/img/visualise.svg").default
},{
  id: 6,
  type: featureType.exploit,
  image: require("assets/img/exploit.svg").default
}]

export const caseStudies = [{
  num: 1,
  slug: 'digital-airfield',
  title: 'Digital\nAirfield Smart\nInfrastructure',
  image: require("assets/img/case-study/digital-airfield.jpg").default,
  video: require("assets/video/case-study-1-digital-airfield.mp4").default,
  filterTypes: [2,3,4,5,6,1,8,11],
  intro: <>Digital Airfield harnesses and exploits the existing data collected in support of both its own air operations and those of its customers. By analysing the data, <ISupportTag/> delivers insight and supports decision making.</>,
  outcomes: <>In medical and police aviation, every second can mean the difference between life and death. <ISupportTag/> is helping us be as efficient as we possibly can be - for both our civil and defence customers.</>,
  features: {
    [featureType.integrate]: 'Connecting data from numerous operational and support sources to provide a rich picture of activity. This has initially included the significant analysis and integration of older, disparate and unstructured information.',
    [featureType.configure]: 'Data is associated to our people and teams, as well as our platforms and equipment in order for us to ensure supply best meets demand.',
    [featureType.acquire]: 'Babcock collects maintenance records, operational data, ERP and planning data sets relating to key assets.',
    [featureType.analyse]: <><ISupportTag/>  enables complex analysis of operational and engineering data sets to predict and optimise asset performance and use.</>,
    [featureType.visualise]: 'Graphical interfaces have been created to allow management across the business to easily identify key areas for deeper analysis.',
    [featureType.exploit]: <><ISupportTag/> has helped our customers make better decisions by analysing mission types, aircraft and equipment usage. In addition, it has helped increase the efficiency of the services Babcock provides, reducing our costs and offering our customers additional services at no additional cost. </>
  }
},{
  num: 2,
  slug: 'fleetview-submarine',
  title: 'Fleetview -\nFleet/Asset\nAvailability',
  image: require("assets/img/case-study/fleetview.jpg").default,
  video: require("assets/video/case-study-2-fleetview-submarine.mp4").default,
  filterTypes: [5,6,2,9],
  intro: 'Currently in service with Babcock Canada, FleetView™ allows users to modify key schedule, resource and planning data to quickly and easily visualise the impact of changes.',
  outcomes: <>“This partnership between Babcock and RaceRocks demonstrates how defence procurements can be leveraged to spur innovative research and development.” <div style={{marginTop: "calc(10px * var(--scale))"}}><i>The Honourable Navdeep Bains Former Minister of Innovation, Science and Economic Development on FleetView™</i></div></>,
  features: {
    [featureType.integrate]: 'FleetView™ links resource and engineering change data to specific maintenance intervals to provide a holistic view of work required.',
    [featureType.configure]: 'By using the individual maintenance events as the unique identifier, engineering changes, resources and other information can be tagged to those events as they become available.',
    [featureType.analyse]: 'Understanding facility loading by analysing resources, engineering change and periods of deviations from mandated policy, the software highlights areas of risk in the programme.',
    [featureType.visualise]: 'Large amounts of complex data can be consumed effectively through a simplified and intuitive user interface that clearly highlights programme risks.',
    [featureType.exploit]: <><ISupportTag/> identifies and mitigate risks before an availability challenge occurs, and optimises the programme for a class or fleet of assets.</>
  }
}, {
  num: 3,
  slug: 'digital-devonport',
  title: 'Digital\nDevonport Smart\nInfrastructure',
  image: require("assets/img/case-study/digital-devonport.jpg").default,
  video: require("assets/video/case-study-3-digital-devonport.mp4").default,
  filterTypes: [1,3,4,5,7,8,12,9],
  intro: <>Devonport is a complex part of Britain’s Critical National Infrastructure, and is essential in ensuring the ongoing readiness of the Royal Navy’s surface and submarine fleet. Digital Devonport is using the <ISupportTag/> toolset to drive innovation and ensure that essential infrastructure is available when needed.</>,
  outcomes: <><ISupportTag/> has enabled Babcock to predict the remaining useable life of key assets at Devonport, and quantify risk in terms of monetary impact.</>,
  features: {
    [featureType.integrate]: 'Digital Devonport combines sensor data with maintenance and configuration databases.',
    [featureType.configure]: 'The asset hierarchy configuration for Digital Devonport is informed by the Enterprise Asset Management system, and is broken down by locations and asset IDs.',
    [featureType.acquire]: 'Digital Devonport operates a range of sensors across the site, enabling the current state of assets to be compared to their original design intent. The site also features a LoRaWAN network for remote sensors and asset tracking applications.',
    [featureType.analyse]: <>Telemetry data is focused on understanding that asset’s material state. The <ISupportTag/> toolset provides understanding of the asset’s remaining useful life, and hence the adoption of predictive maintenance strategies.</>,
    [featureType.visualise]: 'Information about the assets is visualised through a web user interface dashboard, illustrating time series data with CAD overlay. Additional visualisation is through decision-specific dashboards that support engineering outputs.',
    [featureType.exploit]: <>The majority of the assets and equipment of Devonport Royal Dockyard are owned, maintained and operated by Babcock as both the Technical Authority and Design Authority. By employing <ISupportTag/>, Babcock has been able to ensure the ongoing availability and optimisation of the Dockyard in support of Royal Navy operations.</>
  }
}, {
  num: 4,
  slug: 'lorawan-asset-tracking',
  title: 'LoRaWAN\nAsset Tracking',
  image: require("assets/img/case-study/lorawan-asset-tracking.jpg").default,
  video: require("assets/video/case-study-4-lorawan-asset-tracking.mp4").default,
  filterTypes: [5,8,2,3,10,12,11,9],
  intro: <>Babcock provides deep level maintenance and refit to the majority of the British Army’s Armoured Fighting Vehicle Fleet. By using the Internet of Things, <ISupportTag/> has been able to keep track on the thousands of components spread across the Warrior MRO facility at Donnington, Telford.</>,
  outcomes: <>Thanks to <ISupportTag/>, the task of keeping track of all components across a large site has been made considerably easier, speeding up workflows and removing wasted time.</>,
  features: {
    [featureType.integrate]: <>Using <ISupportTag/>, we have been able to combine the existing scheduling and planning information with real-time tracking of the components and assemblies.</>,
    [featureType.acquire]: 'Asset tracking has been achieved across the site through the use of low-cost LoRaWAN sensors, attached to the individual parts or storage containers.',
    [featureType.analyse]: 'Dashboards showing real-time data allow operators to identify bottlenecks or stagnant components, enabling optimisation of overhaul process, minimising disruption and programme risk.',
    [featureType.visualise]: 'Real time geospatial visibility of key components across site enables operators to quickly locate missing assets.',
    [featureType.exploit]: <>In addition to removing time wasted looking and waiting for missing components, <ISupportTag/> has played a significant role in analysing the MRO facility and developing Lean processes by tracking component flows.</>
  }
}, {
  num: 5,
  slug: 'type-23-readiness',
  title: 'Type 23\nReadiness',
  image: require("assets/img/case-study/type-23.jpg").default,
  video: require("assets/video/case-study-5-type-23-readiness.mp4").default,
  filterTypes: [1,3,4,6,5,9],
  intro: 'As Class Output Manager for the Royal Navy’s Type 23 Frigates, Babcock has developed a comprehensive toolset that captures key information about the material state of each platform, providing greater insight, both for warship support and for the Royal Navy’s operational planning.',
  outcomes: <>The Type 23 frigates remain the workhorse of the Royal Navy. By adopting <ISupportTag/>, Babcock has been able to improve safety and platform availability as well as reduce through-life support costs.</>,
  features: {
    [featureType.integrate]: <><ISupportTag/> fully integrates data from both our customer’s and our own foundation systems and combines it with platform telemetry.</>,
    [featureType.configure]: 'Each platform’s asset hierarchy is extracted to customer foundation systems and configured with asset material state data.',
    [featureType.acquire]: 'As part of the Power Generation Machinery Upgrade Programme, we have been able to capture significant real-time data about the real-time state of the platform. In addition, LoRaWAN is used as a bearer layer throughout the Frigate Repair Facility.',
    [featureType.analyse]: <><ISupportTag/> delivers real time supportability analysis, enabling an improved Technical Authority capability for systems and reliability engineering. The toolset includes Complex Asset Predictive Analytics (CAPA), Red Cube and bespoke equipment Remaining Useful Life models.</>,
    [featureType.visualise]: <>A key function within the Type 23 programme is the Operations Centre, which relies heavily on the <ISupportTag/> data analytics, structural and systems 3D mapping and configuration tools.</>,
    [featureType.exploit]: <><ISupportTag/> is an integral part of the Type 23 programme, providing insight into refit planning as well as fleet-time maintenance and operations.</>
  }
}, {
  num: 6,
  slug: 'type-31',
  title: 'Type 31\nDigital Thread',
  image: require("assets/img/case-study/type-31.jpg").default,
  video: require("assets/video/case-study-6-type-31.mp4").default,
  filterTypes: [3,4,7,5,9],
  intro: 'Currently under construction in Babcock’s Rosyth facility, the Type 31 frigate is significantly different to previous ship construction projects, as it places through-life data right at the heart of the programme.',
  outcomes: 'The Digital Thread is a holistic view of an asset’s data throughout its entire lifecycle. This standards-based approach creates a powerful framework of data and digital technologies that enhances design, build and in-service support.',
  features: {
    [featureType.integrate]: 'All data generated as part of the design and build programme is being carefully compiled, assimilated and configured to ensure its usefulness at later stages of the lifecycle.',
    [featureType.configure]: 'Babcock’s support team have played an integral role during the design and build stages, ensuring that the data created is structured and configured to facilitate support of the platform when it enters service.',
    [featureType.acquire]: 'Once the platforms enter service, a comprehensive data acquisition system will provide real-time insight for both the on-board maintainers, Navy Command and the shore-based support organisation.',
    [featureType.analyse]: 'Through mathematical modelling we provide a dynamic overview of the asset material state through-life, enabling targeted support interventions against known risk.',
    [featureType.visualise]: 'The risk picture can be can be visualised and accessed by all stakeholders, providing a common source of truth in a safe and cyber secure environment.',
    [featureType.exploit]: 'The structured nature of the Digital Thread will provide a significant advantage to all stakeholders of the Type 31 fleet, providing operational insight, supporting the maintainers, and facilitating both logistic support and future upgrades and refits.'
  }
}, {
  num: 7,
  slug: 't45-gun',
  title: "4.5” Gun\nReliability",
  description: "By creating a digital twin of the forty-year old gun, we are able to investigate gun failures and hence improve reliability, either through engineering intervention, improved maintenance regiemes or Maintainer training.",
  image: require("assets/img/case-study/t45-gun.jpg").default,
  video: require("assets/video/case-study-7-t45-gun.mp4").default,
  filterTypes: [1,5,3,7,9],
  intro: 'Through our proprietary data capture technology, Babcock is transforming an old yet critical asset into a modern, connected and more reliable system.',
  outcomes: <><ISupportTag/> has already identified key areas where we can further improve the reliability of the 4.5” gun. As <ISupportTag/> rolls out across the fleet, we will further increase reliability, provide more support to the maintainers, and give the Navy greater confidence in its guns.</>,
  features: {
    [featureType.integrate]: 'Babcock is capturing engineering and usage data from the guns’ existing monitoring system, as well as other engineering and support datasets',
    [featureType.acquire]: 'Traditional health and usage systems have relatively slow sample rates. By developing our own advanced data acquisition unit, Babcock has been able to gain far greater insight into how the 4.5 gun operates.',
    [featureType.analyse]: 'The information provided by both the new data unit and detailed analysis of the existing system has allowed Babcock to create a realistic simulation of the forces at work within the gun. This allows us to identify areas to improve reliability and increase the guns remaining useful life.',
    [featureType.visualise]: 'Through edge computing, the advanced data unit developed by Babcock will provide operators with an immediate and accurate view of system health. By analysing at the edge, it also means that only key information needs to be sent back to shore, reducing the transmission overhead.',
    [featureType.exploit]: 'Placing the Digital Twin onto the platform allows us to capture and analyse the data that counts - without the need to send it back to base. It provides actionable insight for the maintainer and allows Babcock to better plan and deliver our support services in order to reduce cost and increase gun availability.'
  }
},{
  num: 8,
  slug: 'bulldog-armoured-vehicle',
  title: 'Bulldog Armoured\nFighting Vehicle\nAvailability',
  image: require("assets/img/case-study/bulldog.jpg").default,
  video: require("assets/video/case-study-8-bulldog-armoured-vehicle.mp4").default,
  filterTypes: [1,3,6,7,10],
  intro: 'A stalwart legacy asset, the Bulldog remains a key capability for the British Army. Through our proprietary data capture, analytics and off-boarding technologies, Babcock is delivering advanced platform systems monitoring and predictive analytics to inform decision making and optimise platform availability.',
  outcomes: <><ISupportTag/> in partnership with the British Army is delivering an advance programme of Condition Monitoring that uses Digital  Twinning technology to gain deeper insight.</>,
  features: {
    [featureType.integrate]: 'Although the Bulldog has an existing usage monitoring system, like many legacy platforms it has limited capability to understand component health linked to the common failure modes that erode platform availability and impact mission success.  However, we recognise that these existing technologies can be exploited and play an important role when integrated into our broader technical solution.',
    [featureType.configure]: 'Undertaking as much development and configuration of the solution as possible prior to installation reduces the impact on operational activity and means that the solution can be quickly and effectively deployed, adding value from the start, with minimal disruption to platform availability.',
    [featureType.acquire]: 'By adding targeted sensors and on-platform data analytics technologies, Babcock is able to assess the performance of key platform systems and diagnose / detect the early onset of failures whilst the vehicle is in use.',
    [featureType.analyse]: 'Initial analysis of the Bulldog engine has been undertaken in partnership with the University of Surrey, with a Bulldog engine being analysed in order to create an accurate physics model - the first stage in creating the Digital Twin.',
    [featureType.visualise]: 'Through edge computing, the advanced data analytics unit developed by Babcock will enable operators and maintainers to have an accurate view of system health. This information is visualised through Babcock’s Information Exploitation hub, to put the information in the hand of those who need it.',
    [featureType.exploit]: 'Through the pilot work undertaken on Bulldog, Babcock has already gained significant insight into key areas of concern on the engine and its maintenance strategy. Data acquisition units are now being deployed on trial platforms within the Bulldog fleet and will assess performance on upcoming Army exercises. In the future, the programme will be able to provide insight direct to the maintainer/operator, as well as information to Army Command on the readiness and availability of the fleet.'
  }
}, {
  num: 9,
  slug: 'armoured-vehicle-readiness',
  title: 'Armoured\nFighting Vehicle\nReadiness',
  image: require("assets/img/case-study/armoured-fighting.jpg").default,
  video: require("assets/video/case-study-9-armoured-vehicle-readiness.mp4").default,
  filterTypes: [2,4,6,10],
  intro: <>As equipment support partner to the British Army, Babcock is responsible for the maintenance and repair of over 24,000 vehicles. <ISupportTag/> provides the insight on both availability of the Armoured Fighting Vehicle fleet and its readiness to support the Army’s exercises and operations.</>,
  outcomes: <>Using <ISupportTag/>, Babcock serves a key role to the British Army, providing insight and intelligence about the availability and readiness of its fleet of Armoured Fighting Vehicles.</>,
  features: {
    [featureType.integrate]: 'In order to predict readiness, we draw upon a very broad data set, ranging from an asset’s material state, it’s allocation and current tasking, through to the availability of strategic lift and operational demand.',
    [featureType.configure]: 'In addition to the data exploitation provided by Babcock, we are also able to assess the quality and provenance of our customers’ own data in order to provide a common operating picture.',
    [featureType.acquire]: 'In addition to capturing off-platform data, we are increasing the use of on-board sensor data, including vehicle fleets deployed overseas.',
    [featureType.analyse]: 'Using the wide range of data that our analysts have access to, combined with sophisticated mathematical models, allows Babcock’s analysts to answer specific risk based questions posed by our Army customers.',
    [featureType.visualise]: 'Information is provided in a range of bespoke reports and dashboards.',
    [featureType.exploit]: <>Using the insight provided by <ISupportTag/>, Babcock is able to advise the British Army on the availability of its AFV fleet and how it can be increased to meet a specific task. Furthermore, by looking at the broader support picture, we can provide a clear picture of fleet readiness and the notice to effect.</>
  }
}, {
  num: 10,
  slug: 'light-unmanned-aircraft',
  title: 'Light\nUnmanned Aircraft\nDigital Twin',
  image: require("assets/img/case-study/unmanned-aircraft.jpg").default,
  video: require("assets/video/case-study-10-light-unmanned-aircraft.mp4").default,
  filterTypes: [1,3,4,5,6,11],
  intro: 'As a major provider of aerial firefighting services in Europe and Canada, Babcock has developed an innovative Light Unmanned Aircraft (LUA) in Spain to spot potential fires. By creating a Digital Twin of the LUA, Babcock’s engineering team has gained a deeper and richer insight into the system and how it performs in operations.',
  outcomes: 'Creating a digital twin of LUA has allowed Babcock’s engineers to ‘look inside’ the aircraft during flight and directly compare its performance with previous missions.',
  features: {
    [featureType.integrate]: 'Babcock captures data from sensors across the asset and combines this with engineering and operational data sets.',
    [featureType.configure]: 'The Digital Twin uses an asset hierarchy of the platform that aggregates the data about various components and sub-systems. This is then connected to data relating to each individual flying mission.',
    [featureType.acquire]: 'Data from sensors measuring key parameters on the asset are combined with environmental and location data to form a rich picture.',
    [featureType.analyse]: 'Babcock’s engineers use a combination of techniques, including outlier detection, machine learning and predictive analytics to provide deep insight into the LUA’s performance.',
    [featureType.visualise]: 'Large and detailed data sets are visualised through a user-friendly interface, allowing granular analysis of trends and outliers.',
    [featureType.exploit]: 'The LUA Digital Twin has provided significant value during the development of this innovative unmanned aircraft, and has allowed Babcock’s engineers and designers to review and compare key platform and environmental parameters between missions.'
  }
}, {
  num: 11,
  slug: 'equipment-supply-chain',
  title: 'Equipment\nSupply Chain\nOptimisation',
  image: require("assets/img/case-study/equipment.jpg").default,
  video: require("assets/video/case-study-11-equipment-supply-chain.mp4").default,
  filterTypes: [2,9,10],
  intro: <>Effective supply chain management is a key driver to asset availability, performance and cost-effectiveness. <ISupportTag/> helps Babcock and our suppliers deliver an affordable, reliable, agile and optimised equipment and spares service.</>,
  outcomes: 'Using its Red Cube optimisation software, Babcock has driven equipment support performance up to 95%.',
  features: {
    [featureType.integrate]: <>Using <ISupportTag/>, we integrate the customers LOGIS systems with our own planning tools and our supply chains to deliver a comprehensive view of the equipment supported.</>,
    [featureType.analyse]: 'Babcock’s Red Cube software delivers a comprehensive demand forecast and scenario modelling capability, accompanied by industry leading cost optimised inventory solutions.',
    [featureType.visualise]: 'Various dashboards and visualisation tools are used to deliver data driven insight, focused action and provide transparency to our customers.',
    [featureType.exploit]: 'A rich data picture ensures that Babcock can deliver an automated supply solution, optimised to the needs of the user to assure the right equipment, at the right place, at the right time.'
  }
}, {
  num: 12,
  slug: 'submarine-availability',
  title: 'Submarine\nAvailability\nManagement',
  image: require("assets/img/case-study/submarine.jpg").default,
  video: require("assets/video/case-study-12-submarine-availability.mp4").default,
  filterTypes: [6,5,9],
  intro: 'Babcock’s SubOps Centre provides Babcock and the Royal Navy with a single source of evidence, backed by expert engineering judgement to maximise the availability of the UK’s submarine fleet.',
  outcomes: 'The single version of truth provided by SubOps allows Babcock to have confidence in its decision making and provide expert engineering analysis to the Royal Navy.',
  features: {
    [featureType.integrate]: 'Multiple data sets across the submarine enterprise are accessed and combined.',
    [featureType.configure]: 'The SubOps Centre uses an asset hierarchy model that links equipment and systems to each platform.',
    [featureType.analyse]: 'Babcock undertakes both programme analysis and maintenance analysis, optimising when and what maintenance should be undertaken.',
    [featureType.visualise]: 'The SubOps Centre brings all information into one space, in which all stakeholders can review and analyse programme performance.',
    [featureType.exploit]: 'The insight from SubOps informs the entire submarine support programme, including programme management, engineering and management of Babcock’s in-house resources and of its supply chain.'
  }
},{
  num: 13,
  slug: 'mission-system-availability',
  title: 'Mission Systems\nAvailability',
  image: require("assets/img/case-study/network.jpg").default,
  video: require("assets/video/case-study-13-network-infrastructure.mp4").default,
  filterTypes: [10],
  intro: <>Babcock is looking to apply <ISupportTag/> to a deployed, multiple asset capability in order to provide the commander greater insight into the availability of the distributed system.</>,
  outcomes: <><ISupportTag/> will provide a single picture of system availability and status to the Command, operators and their support contractors.</>,
  features: {
    [featureType.configure]: 'Data will be combined at a System of Systems level to provide a single picture of system and sub-system availability.',
    [featureType.acquire]: 'The project will use existing on-board monitoring systems, complemented with additional monitoring sensors to provide yet deeper insight.',
    [featureType.visualise]: <><ISupportTag/> manipulates large and detailed datasets to present a readily configurable, easily to use interface tailored to the individual needs of the Command, operators and Support Contractors.</>,
    [featureType.exploit]: <>The <ISupportTag/> approach provides a clear definitive snapshot of the system. This enables optimised spares provisioning, reduced whole life costs, increased safety, reduced environmental impact and increased availability.</>
  }
}]