import TextBox from "../../components/TextBox/TextBox";
import styles from "./WhyBabcock.module.scss";
import HexagonFlipper from "../../components/HexagonFlipper/HexagonFlipper";
import {useRef, useState} from "react";
import Hexagon from "../../components/Hexagon/Hexagon";
import HexagonShortButton from "../../components/HexagonShortButton/HexagonShortButton";
import ISupportTag from "../../components/ISupportTag/ISupportTag";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import BigPlayButton from "../../components/BigPlayButton/BigPlayButton";
import cn from "classnames";

const items = [{
  num: 1,
  icon: require('assets/img/customers.svg').default,
  text: 'Customers',
  hiddenText: 'Our customers are turning to us for tomorrow’s technology solutions, delivered by our own specialists and our longstanding partnerships.'
}, {
  num: 2,
  icon: require('assets/img/technology.svg').default,
  text: 'Technology',
  hiddenText: 'We put data and digital capabilities at the forefront of what we deliver to drive innovation and value for our customers.'
}, {
  num: 3,
  icon: require('assets/img/partnerships.svg').default,
  text: 'Partnerships',
  hiddenText: 'We drive collaboration, across the ecosystem, supporting smaller businesses and bringing our stakeholders together.'
}, {
  num: 4,
  icon: require('assets/img/people.svg').default,
  text: 'People',
  hiddenText: <div><ISupportTag/> gives our people the insight to help our customers make the best informed decisions on asset performance and material condition.</div>
}]

export default function WhyBabcock() {
  const [activeNum, setActiveNum] = useState(null);
  const handleItemClick = (num) => {
    setActiveNum(num);
  }

  const video = useRef(null);

  const [videoMuted, setVideoMuted] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);

  const playVideo = () => {
    video.current.play()
  }

  const pauseVideo = () => {
    video.current.pause();
  }

  const rewindVideo = () => {
    video.current.currentTime -= 15
  }

  const forwardVideo = () => {
    video.current.currentTime += 15
  }

  const onVideoEnded = () => {
    video.current.currentTime = 0;
  }

  const onVideoClick = (event) => {
    if (!videoCanPlay) {
      return
    }
    videoPlaying ? pauseVideo() : playVideo()
  }

  return (
    <div className="page">
      <TextBox>
        <h2>Babcock - innovation in support</h2>
        <p>Underpinning <ISupportTag/> is Babcock’s approach to support, delivering innovation by investing in our people and technology, building relationships, and remaining focused on our customers and the needs of their users.</p>
      </TextBox>

      <div className={styles.hexagons}>

        {items.map(item => (
          <div key={item.num} className={`${styles.item} ${styles['item__' + item.num]}`}>
            <HexagonFlipper
              num={item.num}
              activeNum={activeNum}
              icon={item.icon}
              text={item.text}
              hiddenText={item.hiddenText}
              onItemClick={handleItemClick}
              bigIcon={true}
            />
          </div>
        ))}

        <div className={`${styles.item} ${styles.item__center}`}>
          <Hexagon strokeOnly noPointerEvents />
          <p><b>Innovation in Support</b> <br/>Underpinning <ISupportTag/> is our deep engineering expertise</p>
        </div>

      </div>
      <div className={styles.hexagonVideo}>

        <Hexagon className={styles.hexagonVideo__overlay} thinStrokes noPointerEvents/>

        {/*NOTE the video source has #t=001 added to url to make video thumbnails load on iOS */}
        <video
          ref={video}
          onClick={onVideoClick}
          onEnded={onVideoEnded}
          onPlay={()=> setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          onCanPlay={() => setVideoCanPlay(true)}
          muted={videoMuted}
          preload="auto"
        >
          <source src={require("assets/video/why-babcock-square.mp4").default + "#t=0.001"} type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
        </video>

        <LoadingSpinner show={!videoCanPlay}/>
        <BigPlayButton show={videoCanPlay && !videoPlaying}/>

        <div className={cn(styles.videoButton, {
          [styles.videoButton__disabled]: !videoCanPlay}
        )}>
          <HexagonShortButton  flexInner paddingInner >
            <img src={require("assets/img/video-rewind.svg").default} onClick={rewindVideo} alt="" />
            { videoPlaying
              ? <img src={require("assets/img/video-pause.svg").default} onClick={pauseVideo} alt=""/>
              : <img src={require("assets/img/video-play.svg").default} onClick={playVideo} alt=""/>
            }
            <img src={require("assets/img/video-forward.svg").default} onClick={forwardVideo} alt=""/>
            { videoMuted
              ?  <img src={require("assets/img/video-sound-off.svg").default} onClick={() => setVideoMuted(false)} alt=""/>
              : <img src={require("assets/img/video-sound-on.svg").default} onClick={() => setVideoMuted(true)} alt=""/>
            }

          </HexagonShortButton>
        </div>
      </div>

    </div>
  )
}