import styles from "./Header.module.scss"
import {useLocation} from "react-router-dom";
import cn from "classnames";

export default function Header() {
  const {pathname} = useLocation()
  const isHidden = pathname.startsWith('/case-studies/') || pathname.startsWith('/how-it-works')

  const headerClass = cn(styles.header, {
    [styles.hide]: isHidden
  })

  return (
    <header className={headerClass}>
      <img className={styles.header__logo} src={require("assets/img/babcock-logo.svg").default} alt="logo"/>
    </header>
  )
}