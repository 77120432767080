import styles from './Nav.module.scss'
import NavHexagon from "./NavHexagon";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import cn from "classnames";
import ISupportTag from "../ISupportTag/ISupportTag";

export default function Nav() {
  const {pathname} = useLocation()
  const getLinkClass = (linkPath, isExact = false) => {
    if (isExact) {
      if (pathname === linkPath) {
        return styles.current
      }
    } else {
      if (pathname.startsWith(linkPath)) {
        return styles.current
      }
    }

    return null;
  }

  const onCaseStudyDetail = pathname.startsWith('/case-studies/');
  const hasExtraLink = onCaseStudyDetail;

  const navClass = cn(styles.menu, {
    [styles.hasExtraLink]: hasExtraLink
  })

  return (
      <nav className={navClass}>
        <ul>
          <li className={getLinkClass('/', true)}>
            <NavHexagon/>
            <Link to="/">Home</Link>
          </li>
          <li className={getLinkClass('/the-challenge')}>
            <NavHexagon/>
            <Link to="/the-challenge">The challenge</Link>
          </li>
          <li className={getLinkClass('/isupport')}>
            <NavHexagon/>
            <Link to="/isupport"><ISupportTag/></Link>
          </li>
          <li className={getLinkClass('/how-it-works')}>
            <NavHexagon/>
            <Link to="/how-it-works">How it works</Link>
          </li>
          <li className={getLinkClass('/case-studies')}>
            <NavHexagon/>
            <Link to="/case-studies">Use Cases</Link>
          </li>
          <li className={getLinkClass('/why-babcock')}>
            <NavHexagon/>
            <Link to="/why-babcock">Why Babcock?</Link>
          </li>
          <li className={getLinkClass('/find-out-more')}>
            <NavHexagon/>
            <Link to="/find-out-more">Find out more</Link>
          </li>

          { onCaseStudyDetail &&
            <li className={styles.extraLink}>
              <NavHexagon/>
              <Link to="/case-studies">Close</Link>
            </li>
          }
        </ul>
      </nav>
  )
}