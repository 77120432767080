import TextBox from "../../components/TextBox/TextBox";
import styles from "./FindOutMore.module.scss";
import ISupportTag from "../../components/ISupportTag/ISupportTag";
import cn from "classnames";
import {useState} from "react";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export default function FindOutMore() {
  const [formLoaded, setFormLoaded] = useState(false);


  const embedCls = cn(styles.formEmbed, {
    [styles.formEmbed__loaded]: formLoaded
  })

  return (
    <div className="page">
      <TextBox className={styles.textBox}>
        <h2>Find out more</h2>
        <p>For more information about Babcock, <ISupportTag/> and how we can help you to better manage your assets, please get in touch below.</p>
        <p className={styles.labelRow}>
          <svg className={styles.icon} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
          </svg>
          babcockinternational.com/what-we-do/technology/iSupport360
        </p>
        <p className={styles.labelRow}>
          <svg className={styles.icon} fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76" version="1.1">
            <path d="M 63.332031 12.667969 L 12.664062 12.667969 C 9.183594 12.667969 6.363281 15.515625 6.363281 19 L 6.332031 57 C 6.332031 60.484375 9.183594 63.332031 12.664062 63.332031 L 63.332031 63.332031 C 66.816406 63.332031 69.664062 60.484375 69.664062 57 L 69.664062 19 C 69.664062 15.515625 66.816406 12.667969 63.332031 12.667969 Z M 62.066406 26.125 L 39.675781 40.121094 C 38.664062 40.753906 37.332031 40.753906 36.320312 40.121094 L 13.933594 26.125 C 13.140625 25.617188 12.664062 24.761719 12.664062 23.84375 C 12.664062 21.722656 14.976562 20.457031 16.78125 21.566406 L 38 34.832031 L 59.214844 21.566406 C 61.019531 20.457031 63.332031 21.722656 63.332031 23.84375 C 63.332031 24.761719 62.855469 25.617188 62.066406 26.125 Z M 62.066406 26.125 "/>
          </svg>
          technology@babcockinternational.com
        </p>
      </TextBox>


      <iframe
        src="https://www.smartsurvey.co.uk/s/Y8FOCF/"
        className={embedCls}
        frameBorder="0"
        onLoad={() => setFormLoaded(true)}
      />

      <LoadingSpinner show={!formLoaded} className={styles.formEmbed__spinner}/>

    </div>
  )
}