import styles from "./NavHexagon.module.scss"

export default function NavHexagon () {
  return (
    <svg
      className={styles.hexagon}
      viewBox="0 0 167 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.85 2H15.21L2 24.9L15.21 47.79H151.85L165.06 24.9L151.85 2Z"
      />
    </svg>
  )
}