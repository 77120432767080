import styles from './Hexagon.module.scss'
import cn from "classnames"

const Hexagon = ({id, number, thinStrokes = false, className, strokeOnly, whiteFillOnly, noPointerEvents, onShapeClick}) => {
  // random milliseconds between 1 and 4000
  const randomStart = Math.floor((Math.random() * 4000)) + 1;

  const baseStyle = thinStrokes ? {strokeWidth: 1.5} : null
  const glowStyle = {
    animationDelay: randomStart + 'ms'
  }
  if (thinStrokes) {
    glowStyle.strokeWidth = 1.5
  }

  const hexagonClassName = cn(styles.hexagon, className, {
    [styles.hexagon__whiteFill]: whiteFillOnly,
    [styles.hexagon__noPointerEvents]: noPointerEvents,
    [styles.hexagon__strokeOnly]: strokeOnly
  })

  return (
    <svg
      className={hexagonClassName}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 315 273"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        onClick={() => onShapeClick && onShapeClick()}
        className={styles.hexagonBase}
        style={baseStyle}
        d="M79.616,271.399L1.73204,136.5L79.616,1.60101L235.384,1.601L313.268,136.5L235.384,271.399L79.616,271.399Z"
      />

      <path
        className={styles.hexagonGlow}
        style={glowStyle}
        d="M79.616,271.399L1.73204,136.5L79.616,1.60101L235.384,1.601L313.268,136.5L235.384,271.399L79.616,271.399Z"
      />
    </svg>
  )
}

export default Hexagon