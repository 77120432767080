import styles from "./BigPlayButton.module.scss"
import cn from "classnames";

export default function BigPlayButton({show}) {
  const cls = cn(styles.btn, {
    [styles.hidden]: !show
  })
  return (
    <div className={cls}>
      <img className={styles.icon} src={require("assets/img/video-play.svg").default} alt=""/>
    </div>
  )
}