import TextBox from "../../components/TextBox/TextBox";
import styles from "./Challenge.module.scss";
import HexagonFlipper from "../../components/HexagonFlipper/HexagonFlipper";
import {useState} from "react";

const challenges = [{
  num: 1,
  icon: require("assets/img/motor.svg").default,
  text: <>How do you find out what's really going <br/>on when your asset<br/> is being used?</>,
  hiddenText: <>We keep old equipment going for longer by managing risks through engineering and support
    interventions.</>
}, {
  num: 2,
  icon: require("assets/img/enterprise.svg").default,
  text: <>How do you bring together all the data that is spread across the enterprise?</>,
  hiddenText: <>We capture information from all available sources and make it accessible to all stakeholders.</>
}, {
  num: 3,
  icon: require("assets/img/search.svg").default,
  text: <>How do you keep track of your assets and know that they’re ready when<br/>you need them?</>,
  hiddenText: <>By combining the data, we provide you with a clear picture of your assets and their state of
    readiness. </>
}, {
  num: 4,
  icon: require("assets/img/person.svg").default,
  text: <>How do you ensure the maintainer has the information and skills<br/>they need?</>,
  hiddenText: <>We provide the maintainer with real-time insight into the asset’s performance and the knowledge to keep it operational.</>
}, {
  num: 5,
  icon: require("assets/img/deliver.svg").default,
  text: <>How can you modify the asset to make it more reliable or effective?</>,
  hiddenText: <>We analyse the asset’s performance and identify engineering interventions that have the most impact.</>
}, {
  num: 6,
  icon: require("assets/img/train.svg").default,
  text: <>How do you keep aging yet essential assets<br/>going for longer?</>,
  hiddenText: <>We keep old equipment going for longer by managing risks through engineering and support
    interventions.</>
}, {
  num: 7,
  icon: require("assets/img/calendar.svg").default,
  text: <>How do you optimise your maintenance scheduling and prioritise<br/>tasking?</>,
  hiddenText: <>We predict the future health of an asset based on its current condition and the operational demand profile.</>
}, {
  num: 8,
  icon: require("assets/img/boxes.svg").default,
  text: <>How do you know<br/>which spares to keep<br/>and where to<br/>keep them?</>,
  hiddenText: <>We prioritise spares based on their cost, availability and impact to your operations. </>
}, {
  num: 9,
  icon: require("assets/img/robot.svg").default,
  text: <>How do you introduce automation to reduce the support burden for<br/>your asset?</>,
  hiddenText: <>By analysing the asset and support network, we introduce automation processes and artificial
    intelligence.</>
}, {
  num: 10,
  icon: require("assets/img/keys.svg").default,
  text: <>How do you retain<br/>control of all the relevant information that relates<br/>to your asset?</>,
  hiddenText: <>We always aim to use your own systems and databases, improving data quality and ensuring you stay in control.</>
}]


export default function Challenge() {
  const [activeNum, setActiveNum] = useState(null);
  const handleItemClick = (num) => {
    setActiveNum(num);
  }

  return (
    <div className="page">
      <TextBox>
        <h2>The challenge of<br/> managing complex assets</h2>
        <p>Supporting complex and critical assets is a challenge. The changing technology landscape is more complex and requires different skills. Older equipment is still depended upon and reducing costs, whilst improving availability, and doing more with less remains as important as always.</p>
        <p>Babcock is a technology-led business combining our deep engineering expertise in technology, data science and analytics, means we can help our customers meet these ongoing challenges.</p>
        <p>What challenges affect your organisation?<br/>Click the panels to see how we can help</p>
      </TextBox>

      <div className={styles.hexagons}>

        {challenges.map(challenge => (
          <div key={challenge.num} className={`${styles.item} ${styles['item__' + challenge.num]}`}>
            <HexagonFlipper
              num={challenge.num}
              activeNum={activeNum}
              icon={challenge.icon}
              text={challenge.text}
              hiddenText={challenge.hiddenText}
              onItemClick={handleItemClick}
            />
          </div>
        ))}

      </div>
    </div>
  )
}