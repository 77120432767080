import styles from "./HexagonShortButton.module.scss";
import cn from "classnames";

export default function HexagonShortButton({children, flexInner= false, paddingInner = false, className}, ) {
  const wrapperClass = cn(styles.wrapper, className, {
    [styles.paddingInner]: paddingInner,
    [styles.flexInner]: flexInner
  });
  return (
    <div className={wrapperClass}>
      <div className={styles.inner}>
        {children}
      </div>
    </div>
  )
}