import Hexagon from "../../components/Hexagon/Hexagon";
import styles from "./Home.module.scss"
import TextBox from "../../components/TextBox/TextBox";
import {withRouter} from "react-router-dom";
import HexagonShortButton from "../../components/HexagonShortButton/HexagonShortButton";
import cn from "classnames";
import {useState, useRef} from "react";
import ISupportTag from "../../components/ISupportTag/ISupportTag";

const Home = ({history}) => {
  const videos = {
    looping: 'looping',
    vision: 'vision'
  }
  const [videoToPlay, setVideoToPlay] = useState(videos.looping);
  const makeNavigateTo = (path) => () => {
    history.push(path);
  }
  const loopingVideo = useRef(null)
  const visionVideo = useRef(null)

  const loopingVideoClass = cn({
    [styles.hideVideo]: videoToPlay !== videos.looping
  })

  const visionVideoClass = cn({
    [styles.hideVideo]: videoToPlay !== videos.vision
  })

  const playLoopingVideo = () => {
    setVideoToPlay(videos.looping);
    visionVideo.current.currentTime = 0;
  }

  const playVisionVideo = () => {
    setVideoToPlay(videos.vision);
    visionVideo.current.play();
  }

  const cancelVisionVideo = () => {
    visionVideo.current.pause();
    playLoopingVideo();
  }

  const onVisionVideoEnded = () => {
    playLoopingVideo();
  }

  const videoButtonClass = cn(styles.videoButton, {
    [styles.hidden]: videoToPlay !== videos.looping
  })

  const cancelVideoButtonClass = cn(styles.videoButton, styles.cancelVideoButton, {
    [styles.hidden]: videoToPlay !== videos.vision
  })

  return (
    <div className="page">
      <TextBox isLarge>
        <p><ISupportTag/> provides an integrated digital toolset that enables an asset owner or manager to
          optimise the performance of physical assets; augmenting Babcock's trusted approach with cutting edge digital
          technology.</p>
      </TextBox>

      <div className={styles.hexagonBlock}>

        <div className={`${styles.hexagonBlock__item} ${styles.first}`}>
            <Hexagon onShapeClick={makeNavigateTo('/the-challenge')} />
            <p>The challenge of supporting complex assets</p>
        </div>

        <div className={`${styles.hexagonBlock__item} ${styles.second}`}>

            <Hexagon onShapeClick={makeNavigateTo('/isupport')} />
            <p>What is <ISupportTag/>?</p>
        </div>

        <div className={`${styles.hexagonBlock__item} ${styles.third}`}>
            <Hexagon onShapeClick={makeNavigateTo('/how-it-works')} />
            <p>How <ISupportTag/> works and what it offers</p>
        </div>

        <div className={`${styles.hexagonBlock__item} ${styles.fourth}`}>
            <Hexagon onShapeClick={makeNavigateTo('/case-studies')} />
          <p>How are we using <ISupportTag/> to make a difference?</p>
        </div>

        <div className={`${styles.hexagonBlock__item} ${styles.fifth}`}>
            <Hexagon onShapeClick={makeNavigateTo('/why-babcock')} />
            <p>What makes Babcock different?</p>
        </div>

        <div className={`${styles.hexagonBlock__item} ${styles.videoContainer}`}>
          <Hexagon thinStrokes/>

          {/*Looping Video*/}
          <video ref={loopingVideo} className={loopingVideoClass} muted preload="auto" autoPlay loop>
            <source src={require("assets/video/home-asset-loop.mp4").default} type="video/mp4"/>
              Sorry, your browser doesn't support embedded videos.
          </video>

          {/*Asset Vision Video*/}
          {/*NOTE the video source has #t=001 added to url to make video thumbnails load on iOS */}
          <video ref={visionVideo} className={visionVideoClass} preload="auto" onEnded={onVisionVideoEnded}>
            <source src={require("assets/video/home-vision.mp4").default + "#t=0.001"} type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
          </video>

          <div className={videoButtonClass} onClick={playVisionVideo}>
            <HexagonShortButton>
              <div className={styles.videoButton__inner}>
                <svg className={styles.videoButton__icon} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0255 20.7455L20.5255 14.3705L12.0255 7.99553V20.7455ZM14.8588 0.203857C7.03881 0.203857 0.692139 6.55053 0.692139 14.3705C0.692139 22.1905 7.03881 28.5372 14.8588 28.5372C22.6788 28.5372 29.0255 22.1905 29.0255 14.3705C29.0255 6.55053 22.6788 0.203857 14.8588 0.203857ZM14.8588 25.7039C8.61131 25.7039 3.52547 20.618 3.52547 14.3705C3.52547 8.12303 8.61131 3.03719 14.8588 3.03719C21.1063 3.03719 26.1921 8.12303 26.1921 14.3705C26.1921 20.618 21.1063 25.7039 14.8588 25.7039Z" fill="white"/>
                </svg>
                <span>
                  Watch our vision<br/> for asset management
                </span>
              </div>
            </HexagonShortButton>
          </div>

          <div className={cancelVideoButtonClass} onClick={cancelVisionVideo}>
            <HexagonShortButton>
              <div className={styles.videoButton__inner}>
                <svg className={styles.cancelVideoButton__icon} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="white"/>
                </svg>
                <span>
                  Return
                </span>
              </div>
            </HexagonShortButton>
          </div>

        </div>
      </div>
    </div>
  )
}

export default withRouter(Home)