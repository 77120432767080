import { useCallback, useRef, useState } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import Popup from "../../components/Popup/Popup";
import styles, { documentScale } from "./HowItWorks.module.scss"
import ISupportTag from "../../components/ISupportTag/ISupportTag";
import HexagonShortButton from "../../components/HexagonShortButton/HexagonShortButton";
import cn from "classnames";
import { getWindowScale } from "../../utils/helpers";

const scaled = (x) => x * documentScale
const unscaled = (x) => x / documentScale

let IS_DEBUG = false,
    VIDEO_WIDTH = scaled(1920),
    VIDEO_HEIGHT = scaled(1080),
    POPUPS = [
        {
            x: scaled(2030 / 2 * 1.01), y: scaled(580 / 2 * 1.01),
            w: scaled(200), h: scaled(200),
            title: "User Interface and Visualisation",
            content: <>We create dashboards that harness and integrate all the key information relating to a specific
                asset, system, platform or fleet, and make it available to the people that need it - whether in the
                field or in the office.</>
        },
        {
            x: scaled(2370 / 2 * 1.01), y: scaled(800 / 2 * 1.01),
            w: scaled(200), h: scaled(200),
            title: "Specialist Tools",
            content: <>We use a number of analysis tools that provide deeper insight into specific assets or
                functions.</>
        },
        {
            x: scaled(2550 / 2 * 1.01), y: scaled(910 / 2 * 1.01),
            w: scaled(175), h: scaled(200),
            title: "Artificial Intelligence",
            content: <>We use Data Science and Machine Learning to provide exploratory data analytics, healthy remaining
                useful life models of time series data, image recognition and decision automation.</>
        },
        {
            x: scaled(2740 / 2 * 1.01), y: scaled(1010 / 2 * 1.01),
            w: scaled(175), h: scaled(200),
            title: "Digital Twins",
            content: <>We define a Digital Twin as a connected data representation of an entity, such as a physical
                asset, a process or system, throughout its lifecycle. We build Digital Twins for both new and legacy
                assets.</>
        },
        {
            x: scaled(991 / 2 * 1.01), y: scaled(946 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "Internet of Things",
            content: <>The Internet of Things (IoT) offers great potential for providing real-time insight into an
                assets material condition, location and environment. We use IoT devices ranging from simple Radio
                Frequency Identification Device (RFID) tags through to the complete integration of an asset’s control
                system. </>
        },
        {
            x: scaled(840 / 2 * 1.01), y: scaled(1025 / 2 * 1.01),
            w: scaled(150), h: scaled(125),
            title: "Enhanced Data Acquisition",
            content: <>Where existing monitoring systems do not provide enough detail about an assets condition, Babcock
                can add its high-accuracy, multi channel ADAPTA module (Advanced Data Acquisition, Processing,
                Transmission and Analysis), specifically developed for the development of Digital Twins of legacy
                assets.</>
        },
        {
            x: scaled(644 / 2 * 1.01), y: scaled(1156 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "Health & Usage Monitoring",
            content: <>As well as off-asset data, <ISupportTag/> looks to harness real- and near- real-time data direct
                from the asset’s existing monitoring systems. This includes HUMS modules as well as connecting and
                integrating multiple data sources such as Building Management Systems (BMS).</>
        },
        {
            x: scaled(1250 / 2 * 1.01), y: scaled(1214 / 2 * 1.01),
            w: scaled(200), h: scaled(200),
            title: "Data Identification",
            content: <>Where an asset’s data originates from multiple sources, <ISupportTag/> orchestrates the data into
                a single standard pipeline that unlocks the data for further exploitation.</>
        },
        {
            x: scaled(1514 / 2 * 1.01), y: scaled(1418 / 2 * 1.01),
            w: scaled(175), h: scaled(175),
            title: "Data Validation",
            content: <>Data only becomes really useful if you can trust it. This issue is exacerbated with multiple data
                sources. The data quality processes within <ISupportTag/> ensures we have a single version of the truth
                that can then be integrated and exploited.</>
        },
        {
            x: scaled(827 / 2 * 1.01), y: scaled(1485 / 2 * 1.01),
            w: scaled(175), h: scaled(250),
            title: "Structured Data",
            content: <>Application Programming Interfaces (APIs) are the key to unlocking and accessing the databases
                and other structured data that exists across the enterprise.</>
        },
        {
            x: scaled(1029 / 2 * 1.01), y: scaled(1612 / 2 * 1.01),
            w: scaled(150), h: scaled(250),
            title: "Unstructured Data",
            content: <>All organisations have a wealth of useful information locked up in documentation and other
                ‘difficult to reach’ places. In <ISupportTag/>, we develop and use Extract, Transform and Load (ETL)
                processes that allow us to capture this data.</>
        },
        {
            x: scaled(1276 / 2 * 1.01), y: scaled(1728 / 2 * 1.01),
            w: scaled(200), h: scaled(250),
            title: "Improved Data Fed Back",
            content: <>Babcock believe that the data relating to an asset belongs with that asset - not with a third
                party. Wherever possible, we continue to use our customers existing databases, validating the existing
                data and adding to it.</>
        },
        {
            x: scaled(559 / 2 * 1.01), y: scaled(511 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "Customers",
            content: <>Our customers are turning to us for tomorrow’s technology solutions, delivered by our own
                specialists and our longstanding partnerships.</>
        },
        {
            x: scaled(762 / 2 * 1.01), y: scaled(396 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "People",
            content: <><ISupportTag/> gives our people the insight to help our customers make the best informed
                decisions on asset performance and material condition.</>
        },
        {
            x: scaled(954 / 2 * 1.01), y: scaled(274 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "Partnerships",
            content: <>We drive collaboration across the ecosystem, supporting smaller businesses and bringing our
                stakeholders together.</>
        },
        {
            x: scaled(1139 / 2 * 1.01), y: scaled(166 / 2 * 1.01),
            w: scaled(150), h: scaled(150),
            title: "Technology",
            content: <>We put data and digital capabilities at the forefront of what we deliver to drive innovation and
                value for our customers.</>
        },
        {
            x: scaled(2840 / 2 * 1.01), y: scaled(1373 / 2 * 1.01),
            w: scaled(175), h: scaled(175),
            title: "Enterprise Resource Planning",
            content: <>We use the insight of <ISupportTag/> to help us plan our operations, ensuring we have the right
                people and the right resources in place to deliver effective support.</>
        },
        {
            x: scaled(3092 / 2 * 1.01), y: scaled(1531 / 2 * 1.01),
            w: scaled(175), h: scaled(175),
            title: "Supply Chain Management",
            content: <><ISupportTag/> allows us to better work with our supply chain, giving them greater visibility of
                future demand and optimising logistics and repair cycles.</>
        }, {
            x: scaled(2569 / 2 * 1.01), y: scaled(1522 / 2 * 1.01),
            w: scaled(175), h: scaled(175),
            title: "Product Data Management",
            content: <>Having a single version of the truth relating to an asset’s material state ensures we maintain
                configuration control and allows us to de-risk engineering and support activities.</>
        }, {
            x: scaled(2860 / 2 * 1.01), y: scaled(1678 / 2 * 1.01),
            w: scaled(175), h: scaled(175),
            title: "Product Lifecycle Management",
            content: <><ISupportTag/> is applicable throughout an asset’s lifecycle, from design and manufacture,
                through to in-service and eventual disposal. By creating and building the Digital Thread, Babcock is
                able to harness and exploit this information at all stages.</>
        }, {
            x: scaled(1706 / 2 * 1.01), y: scaled(1850 / 2 * 1.01),
            w: scaled(200), h: scaled(200),
            title: "Customer’s Own Systems",
            content: <>We believe in making life as easy as possible for an asset’s users and maintainers. As such, we
                look to present information in the most effective manner. For example, by using our customers’ own
                information systems and hardware.</>
        }, {
            x: scaled(2030 / 2 * 1.01), y: scaled(1390 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/integrate.svg").default,
            size: "medium",
            title: "Integrate",
            content: <>Frequently, the data relating to an asset resides across multiple sources, all of which have
                various configurations and standards. By orchestrating those sources to a single standard pipeline, we
                are able to unlock the data for exploitation.<br/><br/>
                We recognise that the master data source for an asset will change and evolve over its life cycle, and
                varies depending on its ownership, deployment infrastructure or data standard. Where possible Ideally,
                the configuration and analyse functions of <ISupportTag/> should be abstracted and agnostic from these
                changes, maintaining a constant pipeline post orchestration.<br/><br/>
                <ISupportTag/> provides the necessary Application Programming Interfaces (APIs) and Extract, Transform
                and Load (ETL) processes to capture this data - whatever the format, and then validates it in order to
                ensure it is as accurate as possible.</>
        }, {
            x: scaled(2780 / 2 * 1.01), y: scaled(1170 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/configure.svg").default,
            size: "medium",
            title: "Configure",
            content: <>Structuring an asset hierarchy and populating with data is essential for supporting engineering
                analysis. <ISupportTag/> allows the asset manager and engineer to create a custom asset
                hierarchy.<br/><br/>
                This rolls up from an individual piece of equipment, to its parent system, the system of systems or
                platform, and up to the platform fleet. Each element of the hierarchy is populated from the master data
                set based on user created configuration.<br/><br/>
                Managing this data structure and model can be challenging. <ISupportTag/> solves this challenge through
                use of its dynamic configuration driven, “actor” model solution. Creation of asset hierarchies and their
                defining business rules remains scalable and computationally optimised even for fleets of complex
                assets.</>
        }, {
            x: scaled(862 / 2 * 1.01), y: scaled(890 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/acquire.svg").default,
            size: "large",
            title: "Acquire",
            content: <><ISupportTag/> and its partner program, Babcock IoT, provides the enterprise telematics solution
                that simplifies the transmission, receipt and aggregation of edge device data.<br/><br/>
                Assets are connected through these edge devices, which range from simple Radio Frequency Identification
                Devices (RFID) through to complete integration of an assets control system. Where interfacing is
                challenging, or more granular or higher accuracy data is required, we have developed the ADAPTA module
                (Advanced Data Acquisition, Processing, Transmission and Analysis). This robust and innovative approach
                enables us to create and apply Digital Twins for legacy assets.<br/><br/>
                Babcock IoT provides secure data transfer on the most appropriate bearer for the edge device and
                available bandwidth - LoRaWAN for example. We also employ IoT Data Management for provisioning and
                control of edge devices and use various middleware solutions and data collection and integration
                technologies to surface the telemetry into the enterprise data management systems assigned to the asset
                data model.</>
        }, {
            x: scaled(3160 / 2 * 1.01), y: scaled(990 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/analyse.svg").default,
            size: "large",
            title: "Analyse",
            content: <>The analysis of data, combined with Babcock’s engineering expertise, is the core function of
                the <ISupportTag/> toolset. We use a combination of commercial and Babcock-developed tools to help the
                asset users understand the material state and operation of an asset, and hence provide insight into
                current and future performance.<br/><br/>
                Areas of expertise include an Integrated Logistic Support (ILS) solution, featuring reliability and
                probabilistic modelling, Discrete Event Simulation (DES), and technical documentation. Babcock has also
                developed a collaborative Data Science and Machine Learning environment that delivers exploratory data
                analytics, healthy state and remaining useful life models of time series data, image recognition and
                decision automation.<br/><br/>
                Babcock’s Red Cube software enables engineers to manage, plan and cost-optimise the spares inventory
                related to an asset, with the focus being on the high value and long lead-time items that can be
                critical to an asset’s performance.<br/><br/>
                All analysis undertaken is seamlessly integrated with the configured asset hierarchy and data pipeline,
                adding to the body of knowledge and allowing ingestion and future exploitation.</>
        }, {
            x: scaled(1950 / 2 * 1.01), y: scaled(485 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/visualise.svg").default,
            size: "medium",
            title: "Visualise",
            content: <>Visualisation of data combined with engineering expertise is a key enabler in the decision
                process for engineer, operator or maintainer of an asset. <ISupportTag/> uses a mix of commercial and
                Babcock-developed tools and technology to visualise data at the point of need for strategic, tactical
                and operational decision making.<br/><br/>
                Dashboard visualisations are integrated into the asset management process, complementing the engineer’s
                expertise. For asset tracking, infrastructure management and command and control applications,
                Geographic Information Systems provide additional context through geo-spatial visualisation.<br/><br/>
                By combining 3D CAD models of assets with telemetry data, design documentation and insight derived from
                analytics models, it is possible to simulate and solve complex engineering challenges. Visualisation can
                also be deployed via interactive means, such as Virtual Reality and Augmented Reality, further enabling
                operators and maintainers when working directly with assets.</>
        }, {
            x: scaled(2450 / 2 * 1.01), y: scaled(1525 / 2 * 1.01),
            w: scaled(175), h: scaled(48),
            icon: require("assets/img/exploit.svg").default,
            size: "medium",
            title: "Exploit",
            content: <>Babcock’s Foundation Enterprise Asset Management (EAM) Systems’ act as both the supplier and
                customer of the <ISupportTag/> toolset. The insight and decisions provided by the Visualise and Analyse
                elements enable actions to be taken that maintain the availability and capability of asset through
                modification of data in the EAM or data from across the enterprise.<br/><br/>
                These actions can be developed manually as part of the day-to-day decision process, driving changes to
                maintenance, supply chain management or operations. Alternatively, <ISupportTag/> enables the automation
                of business processes, by using rules that compare the analysis to the desired outcomes.<br/><br/>
                The <ISupportTag/> toolset enables this modification with the capability and awareness to protect
                proprietary, customer and third-party information and assets enabling end-to-end traceability and
                accessibility. Furthermore, insight and analysis can be fed back into our customers’ own data
                systems.</>
        },
    ]

function Hotspot({ x, y, icon, onClick, w = 0, h = 0, selected = false }) {
    // the positions were calculated on a full sized video. but the video actually used is slightly undersized.
    // we can fudge the numbers so the items are correctly placed.
    let style = {
        position: 'absolute',
        left: x - (w / 2),
        top: y - (h / 2),
        width: h, // transform adds a rotation, so invert the w&h
        height: w,
        outline: IS_DEBUG ? '10px solid hotpink' : '',
        transition: 'background-color 0.5s , outline 0.5s ',
        transform: `rotate(-45deg) skew(15deg, 15deg)`, // skew the div to make it isometric
    }

    if (icon) {
        style = {
            ...style,
            width: w,
            height: h,
            padding: `${5 * documentScale}px`,
            backgroundColor: "#3B8ACA",
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
            transform: 'translateX(-50%)',
        }
    }

    if (selected) {
        if (icon) {
            style = {
                ...style,
                backgroundColor: "#D43B83",
            }
        }
    }

    if (icon) {
        return <div style={style} onClick={onClick}>
            <img src={icon} style={{width: 'auto', height: "100%"}}/>
        </div>
    } else {
        return <div style={style} onClick={onClick}/>
    }

}

export default function HowItWorks() {
    const canvasRef = useRef()
    const videoRef = useRef()
    const qpzRef = useRef()
    const videoTimeLoop = useRef({ start: 0, finish: 11 })
    const [offsetX, setOffsetX] = useState(0)
    const [offsetY, setOffsetY] = useState(0)
    const [currentScale, setCurrentScale] = useState(1)
    const [visiblePopupIndex, setVisiblePopupIndex] = useState(-1)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const maxZoom = 2
    const minZoom = 1 // this covers the slight delta between video and screen size
    const videoURL = documentScale > 1
        ? require("assets/video/how-it-works-4k.mp4").default
        : require("assets/video/how-it-works-2k.mp4").default

    const onUpdate = useCallback(({ x, y, scale }) => {
        // hide any popup that may be visible
        // dismissPopup()

        // adjust the x + y for the window's scale, and the device's pixel ratio (retina @2x devices)
        // this is needed because this whole frame is scaled, but the input mouse events are not
        // without this, moving the mouse 100px will not move the video exactl 100px
        x = x / getWindowScale();
        y = y / getWindowScale();

        // constrain the x + y to within the bounds of the video
        x = Math.min(0, Math.max(x, (VIDEO_WIDTH / scale) - VIDEO_WIDTH));
        y = Math.min(0, Math.max(y, (VIDEO_HEIGHT / scale) - VIDEO_HEIGHT));

        // console.log(" -- onUpdate", x, y, scale)

        // QuickPinchZoom doesn't have a method to get the current x, y, scale. so we need to save the last known
        // values, so that moveMap() doesn't clobber the current values when it moves the map.
        setOffsetX(x);
        setOffsetY(y);
        setCurrentScale(scale);

        // apply a 3d transform that puts the <video/> tag in the correct position
        canvasRef.current?.style.setProperty("transform", make3dTransformValue({ x, y, scale }))
    }, [])

    const onVideoProgress = useCallback(() => {
        // loop the selected range of the video
        if (!videoRef.current) return;

        if (!isVideoPlaying) {
            setIsVideoPlaying(true) // this will display the markers
        }

        if (videoRef.current.currentTime > videoTimeLoop.current.finish) {
            videoRef.current.currentTime = videoTimeLoop.current.start;
        }
        if (videoRef.current.currentTime < videoTimeLoop.current.start) {
            videoRef.current.currentTime = videoTimeLoop.current.start;
        }
    }, [])

    const moveCanvas = ({ x = 0, y = 0, zoom = 0 }) => {
        let oX = qpzRef.current?._offset.x,
            oY = qpzRef.current?._offset.y,
            oZ = qpzRef.current?._zoomFactor

        qpzRef.current?.scaleTo({
            scale: zoom ? zoom : oZ,
            x: x ? oX + x : oX,
            y: y ? oY + y : oY,
            animated: true,
            duration: 250
        });
    }

    const markerRelativeX = (x) => (offsetX + x) * currentScale
    const markerRelativeY = (y) => (offsetY + y) * currentScale

    const isLeft = (x) => markerRelativeX(x) > scaled(1075)
    const isBottom = (y) => markerRelativeY(y) > scaled(600)
    const isMiddle = (y) => markerRelativeY(y) > scaled(400)

    const showPopup = (index) => {
        setVisiblePopupIndex(index);
    }
    const dismissPopup = () => {
        setVisiblePopupIndex(-1);
    }

    const loopVideo = (start, finish) => {
        dismissPopup() // dismiss any open popups
        videoRef.current.currentTime = start
        videoTimeLoop.current = { start: start, finish: finish }
    }

    return (
        <div className="page">
            <QuickPinchZoom
                ref={qpzRef}
                onUpdate={onUpdate}
                shouldInterceptWheel={() => false} // allow mousewheel zoom on desktop
                inertia={false}
                inertiaFriction={0.85}
                setOffsetsOnce={true}
                tapZoomFactor={2}
                maxZoom={maxZoom}
                minZoom={minZoom}
            >
                <div className={styles.video} ref={canvasRef}>
                    <video ref={videoRef}
                           src={videoURL}
                           autoPlay={true}
                           playsInline={true}
                           loop={true}
                           muted={true}
                           onTimeUpdate={onVideoProgress}
                           onClick={(event) => {
                               dismissPopup()
                               console.log('x: ' + event.nativeEvent.offsetX + ", y: " + event.nativeEvent.offsetY + ',');
                           }}
                    />
                    {isVideoPlaying && <>
                        {POPUPS.map((popup, index) =>
                            <Hotspot
                                {...popup}
                                key={index}
                                selected={visiblePopupIndex === index}
                                onClick={() => {
                                    if (visiblePopupIndex === index) {
                                        dismissPopup()
                                    } else {
                                        showPopup(index)
                                    }
                                }}
                            />
                        )}
                        {/*Submarine*/}
                        <Hotspot
                            x={scaled(428)} y={scaled(335)}
                            w={scaled(175)} h={scaled(100)}
                            onClick={() => loopVideo(1, 11)}/>
                        {/*Warship*/}
                        <Hotspot
                            x={scaled(503)} y={scaled(295)}
                            w={scaled(175)} h={scaled(100)}
                            onClick={() => loopVideo(12.01, 21.8)}/>
                        {/*Tank*/}
                        <Hotspot
                            x={scaled(578)} y={scaled(255)}
                            w={scaled(175)} h={scaled(80)}
                            onClick={() => loopVideo(23.01, 32.8)}/>
                        {/*Helicopter*/}
                        <Hotspot
                            x={scaled(630)} y={scaled(215)}
                            w={scaled(175)} h={scaled(80)}
                            onClick={() => loopVideo(34, 43.8)}/>
                        {/*Police car*/}
                        <Hotspot
                            x={scaled(700)} y={scaled(190)}
                            w={scaled(175)} h={scaled(100)}
                            onClick={() => loopVideo(45, 54.8)}/>
                    </>}
                </div>
            </QuickPinchZoom>

            <div className={styles.controls}>
                <HexagonShortButton flexInner paddingInner>
                    <img src={require("assets/img/zoom-in.svg").default} onClick={() => moveCanvas({
                        zoom: 2,
                        x: unscaled(VIDEO_WIDTH) / 2,
                        y: unscaled(VIDEO_HEIGHT) / 2
                    })}/>
                    <img src={require("assets/img/zoom-out.svg").default} onClick={() => moveCanvas({
                        zoom: 1
                    })}/>
                    <img src={require("assets/img/pan-left.svg").default} onClick={() => moveCanvas({
                        x: scaled(-250),
                        zoom: 2
                    })}/>
                    <img src={require("assets/img/pan-up.svg").default} onClick={() => moveCanvas({
                        y: scaled(-250),
                        zoom: 2
                    })}/>
                    <img src={require("assets/img/pan-down.svg").default} onClick={() => moveCanvas({
                        y: scaled(250),
                        zoom: 2
                    })}/>
                    <img src={require("assets/img/pan-right.svg").default} onClick={() => moveCanvas({
                        x: scaled(250),
                        zoom: 2
                    })}/>
                </HexagonShortButton>
            </div>

            {isVideoPlaying && POPUPS.map((popup, index) => {
                const hasIcon = !!popup.icon;
                const holderCls = cn(styles.circleHolder, {
                    [styles.circleHolder__noWidth]: hasIcon
                })
                const circleCls = cn(styles.circle, {
                    [styles.circle__hidden]: hasIcon,
                    [styles.circle__show]: visiblePopupIndex === index
                })

                return (
                    <div key={index}
                         className={holderCls}
                         style={{
                             left: hasIcon
                                 ? markerRelativeX(popup.x - popup.w)
                                 : markerRelativeX(popup.x - (popup.w / 2)),
                             top: hasIcon
                                 ? markerRelativeY(popup.y - (popup.h / 2))
                                 : markerRelativeY(popup.y - (popup.h / 2)),
                             width: hasIcon
                                 ? popup.w * currentScale
                                 : popup.w * currentScale,
                             height: hasIcon
                                 ? popup.h * currentScale
                                 : popup.w * currentScale // h == w for non icon markers so circle is round
                         }}
                    >
                        <div className={circleCls}>&nbsp;</div>
                        <Popup
                            show={visiblePopupIndex === index}
                            title={popup.title}
                            text={popup.content}
                            size={popup.size ? popup.size : ""}
                            alignBox={isBottom(popup.y) ? "bottom" : isMiddle(popup.y) ? "middle" : "top"}
                            sideOfTarget={isLeft(popup.x) ? "left" : "right"}
                            onBtnClick={() => setVisiblePopupIndex(null)}
                        />
                    </div>
                )
            })}
        </div>
    )
}
