import styles from "./LoadingSpinner.module.scss"
import cn from "classnames";

export default function LoadingSpinner({show, centerInParent=true, className}) {
  const cls = cn(className, styles.loadingSpinner, {
    [styles.hidden]: !show,
    [styles.centerInParent]: centerInParent
  })
  return (
    <img className={cls} src={require("assets/img/loading.svg").default} alt=""/>
  )
}