export default function ISupportTag() {
  const numberStyle ={
    verticalAlign: 'baseline',
    fontSize: '0.7em',
    position: 'relative',
    bottom: '0.4em',
    lineHeight: 0,
    fontWeight: 'bold'
  }
  return (
    <span>iSupport<sup style={numberStyle}>360</sup></span>
  )
}