import './App.scss';
import Header from './components/Header/Header'
import Nav from './components/Nav/Nav'
import Home from './pages/Home/Home'
import './styles/main.scss'
import {useEffect} from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ParticleBackground from "./components/Particles/Particles";

/* Pages */
import Challenge from "./pages/Challenge/Challenge";
import ISupport from "./pages/ISupport/ISupport";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import CaseStudiesDetail from "./pages/CaseStudies/CaseStudiesDetail";
import WhyBabcock from "./pages/WhyBabcock/WhyBabcock";
import FindOutMore from "./pages/FindOutMore/FindOutMore";
import { getWindowScale } from "./utils/helpers";

function App() {
  useEffect(() => {
    function adjustDocumentScale() {
      let scale = getWindowScale()
      document.getElementById("screen").style.transform = `scale(${scale})`;
    }

    window.addEventListener('resize', adjustDocumentScale);
    adjustDocumentScale();
  },[])

  return (
    <Router>
      <Contents/>
    </Router>
  );
}

const Contents = () => {
  const {pathname} = useLocation()
  const onHowItWorksPage = pathname === '/how-it-works';

  return (
    <>
      {!onHowItWorksPage && <ParticleBackground/>}
      <main id="screen">
        <div id="safe-area">
          <Header/>
          <Outlet/>
          <Nav/>
        </div>
      </main>
    </>
  )
}

const Outlet = () => {
  const location = useLocation()
  return (
    <TransitionGroup>
      <CSSTransition
        timeout={400}
        classNames="fade"
        key={location.pathname}
      >
        <Switch location={location}>
          <Route exact path="/" component={Home} />
          <Route exact path="/the-challenge" component={Challenge} />
          <Route exact path="/isupport" component={ISupport}/>
          <Route exact path="/how-it-works" component={HowItWorks}/>
          <Route exact path="/case-studies" component={CaseStudies}/>
          <Route path="/case-studies/:slug" component={CaseStudiesDetail}/>
          <Route exact path="/why-babcock" component={WhyBabcock}/>
          <Route exact path="/find-out-more" component={FindOutMore}/>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default App;
