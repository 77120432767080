import TextBox from "../../components/TextBox/TextBox";
import styles from "./ISupport.module.scss";
import {useState} from "react";
import cn from "classnames";
import Popup from "../../components/Popup/Popup";
import ISupportTag from "../../components/ISupportTag/ISupportTag";

const deliversItems = [{
  popupNum: 8,
  title: 'Asset performance prediction',
  popup: {
    text: 'Predicting the future performance of an asset gives us an insight into its availability and the likelihood of any future failure.'
  }
}, {
  popupNum: 9,
  title: 'Support chain management',
  popup: {
    text: 'Providing the support chain greater insight into demand and optimising spares holdings.'
  }
}, {
  popupNum: 10,
  title: 'Material state management',
  popup: {
    text: 'Giving a clear and definitive snapshot of an asset and enabling ‘what if’ scenario planning.'
  }
}, {
  popupNum: 11,
  title: 'Whole life management',
  popup: {
    text: 'Analysing asset performance over its full lifecycle in terms of cost, financing, risk, safety, environmental impact, availability and utilisation.'
  }
}, {
  popupNum: 12,
  title: 'Asset maintenance, update and upgrade',
  popup: {
    text: 'Optimising maintenance strategies to reduce maintainer burden and increase reliability. Supporting update and upgrade programmes to deliver projects more safely, quickly and at a lower cost. Empowering the maintainer with the right information and documentation at the point of source.'
  }
}, {
  popupNum: 13,
  title: 'Fleet management and forecasting',
  popup: {
    text: 'Providing the information to better manage fleets of assets, ensuring the right asset is in the right place at the right time.'
  }
}, {
  popupNum: 14,
  title: 'Digitally enabled workforce',
  popup: {
    text: 'Providing people with the skills, technology and insight to make their jobs safer, easier and more effective.'
  }
},{
  popupNum: 15,
  title: 'Optimised infrastructure',
  popup: {
    text: 'Ensuring that the enabling infrastructure is fit for task, available when required, and as efficient and sustainable as possible.'
  }
}]

export default function ISupport() {
  const [pulseNum, setPulseNum] = useState(1);
  const [popupNum, setPopupNum] = useState(0);

  const closePopup = (num) => {
    setPopupNum(null);
  }

  const onItemClick = (num) => {
    setPopupNum(num)
    setPulseNum(num + 1)
  }

  const getItemClass = (num) => {
    return cn(styles.diagram__item, styles[`diagram__item__${num}`], {
      [styles.highestStack]: num === popupNum
    })
  }

  const getDotClass = (num) => {
    return cn(styles.diagram__dot, {
      [styles.ping]: num === pulseNum
    })
  }

  return (
    <div className="page">
      <TextBox className={styles.textBox}>
        <h2>What is <ISupportTag/></h2>
        <p>The <ISupportTag/> toolset is a highly adaptable capability, underpinned by a common architecture and combining a blend of off-the-shelf and Babcock-developed tools. Configurable to any customer need, <ISupportTag/> uses open standards to integrate customer systems, new capabilities and data to improve asset performance and support through-life.</p>
      </TextBox>

      <div className={styles.diagram}>
        <h2>System Overview</h2>

        <div className={styles.largeArrow}/>

        <div className={getItemClass(1)} onClick={() => onItemClick(1)}>
          <h3>Data that exists across the enterprise</h3>
          <div className={getDotClass(1)}>
            <Popup
              show={popupNum === 1}
              text={<>We recognise the power of the data that already exists within our own systems and those of our customers and other third-parties. <ISupportTag/> is able to capture and translate this data, whilst protecting our customers’ systems and enabling them to understand the data from their assets.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div className={getItemClass(2)} onClick={() => onItemClick(2)}>
          <h3>Data Orchestration &amp; Integration</h3>
          <div className={getDotClass(2)}>
            <Popup
              show={popupNum === 2}
              icon={require('assets/img/integrate.svg').default}
              text={<><ISupportTag/> transforms data from multiple sources, formats and standards, to a single integrated common data model of an asset. We structure the data using open standards, programmatic interfaces and processes in order to gain greater insight.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div className={getItemClass(3)} onClick={() => onItemClick(3)}>
          <h3>Data Acquisition Systems</h3>
          <div className={getDotClass(3)}>
            <Popup
              show={popupNum === 3}
              icon={require('assets/img/acquire.svg').default}
              text={<>In addition to utilising data from across the enterprise, <ISupportTag/> captures data direct from the platform or equipment - either through existing monitoring systems or by installing high-performance data acquisition units and sensors that employ the Internet of Things.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div  className={getItemClass(4)} onClick={() => onItemClick(4)}>
          <h3>Asset Data Hierarchy</h3>
          <div className={getDotClass(4)}>
            <Popup
              show={popupNum === 4}
              alignBox="top"
              icon={require('assets/img/configure.svg').default}
              text={<><ISupportTag/> simplifies the joining of asset data to analytics models through a dynamically configured visual asset hierarchy, enabling the engineer to drive the decision building process in a low code environment.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div className={getItemClass(5)} onClick={() => onItemClick(5)}>
          <h3>Analytics</h3>
          <div className={getDotClass(5)}>
            <Popup
              show={popupNum === 5}
              alignBox="top"
              icon={require('assets/img/analyse.svg').default}
              text={<><ISupportTag/> uses extensive analytics approaches, both traditional and through AI/ML to transform structured data into information and insight through a synergy of data, simulation modelling and visualisation to drive current and future asset performance optimisation.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div className={getItemClass(6)} onClick={() => onItemClick(6)}>
          <h3>User Interface &amp; Visualisation</h3>
          <div className={getDotClass(6)}>
            <Popup
              show={popupNum === 6}
              alignBox="top"
              icon={require('assets/img/visualise.svg').default}
              text={<><ISupportTag/> gives our customers complete insight into their complex equipment, assets and associated infrastructure; overlaying insights from analytics with the knowledge of our subject matter experts. This combination enables decision making on the key metrics of availability, capability, sustainability and risk and provides the maintainer, at the point of need, with the data and insight they need to get the job done.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>

        <div className={getItemClass(7)} onClick={() => onItemClick(7)}>
          <h3>Foundation Systems</h3>
          <div className={getDotClass(7)}>
            <Popup
              show={popupNum === 7}
              sideOfTarget="left"
              alignBox="top"
              icon={require('assets/img/exploit.svg').default}
              text={<>The insight that <ISupportTag/> delivers is then fed back into Babcock’s own operations and those of our customers. This includes our enterprise planning tools, supply chain management and engineering and logistics functions.</>}
              onBtnClick={closePopup}
            />
          </div>
        </div>
      </div>


      <div className={styles.deliversList}>
        <h2>What it delivers</h2>
        {deliversItems.map(item => {
          const isActive = popupNum === item.popupNum
          const cls = cn(styles.deliversList__item, {
            [styles.highestStack]: isActive,
            [styles.active]: isActive
          })
          return (
            <div className={cls}  onClick={() => setPopupNum(item.popupNum)} key={item.popupNum}>
              {item.title}
              <Popup
                show={isActive}
                sideOfTarget="left"
                alignBox={item.popup.alignBox || "bottom"}
                text={item.popup.text}
                onBtnClick={closePopup}
              />
            </div>
          )
        })}

      </div>
    </div>
  )
}