import TextBox from "../../components/TextBox/TextBox";
import styles from "./CaseStudies.module.scss";
import Hexagon from "../../components/Hexagon/Hexagon";
import {caseStudies, requirementFilters, typeFilters} from "../../data/caseStudies";
import cn from "classnames";
import {withRouter} from "react-router-dom";
import {useState} from "react";
import ISupportTag from "../../components/ISupportTag/ISupportTag";

const totalFiltersSize = requirementFilters.length + typeFilters.length

const CaseStudies = ({history}) => {
  const [filters, setFilters] = useState(new Set());
  const goToDetail = (slug) => {
    history.push(`/case-studies/${slug}`);
  }
  const toggleFilters = (newId) => {
    const newFilters = new Set(filters);

    if (newFilters.has(newId)) {
      newFilters.delete(newId)
    } else {
      newFilters.add(newId)
    }

    // just if user has turned all filters on - if they have, then it's the same logic as calling reset filters
    if (newFilters.size === totalFiltersSize) {
      resetFilters()
    } else {
      setFilters(newFilters)
    }
  }

  const resetFilters = () => {
    setFilters(new Set())
  }

  const filtersOn = filters.size > 0;

  const resetCls = cn(styles.reset, {
    [styles.hidden]: !filtersOn
  })

  return (
    <div className="page">
      <TextBox>
        <h2>Use Cases - <ISupportTag/> In Action</h2>
        <p><ISupportTag/> is already delivering benefits for our customers across all domains. Click on the case study to find out more.</p>
      </TextBox>

      <div className={styles.navLeft}>
        <p>Click on the elements below to filter by requirement</p>
        {requirementFilters.map(filter => {
          const cls = cn(styles.btn, {
            [styles.disabled]: (filtersOn && !filters.has(filter.id))
          })
          return (<div className={cls} key={filter.id} onClick={() => toggleFilters(filter.id)}>{filter.text}</div>)
        })}

        <div className={styles.navLeftBottom}>
          <p>Click on the elements below to filter by asset type</p>
          <div className={styles.btnWrapper}>
            {typeFilters.map(filter => {
              const cls = cn(styles.btn, {
                [styles.disabled]: (filtersOn && !filters.has(filter.id))
              })
              return(<div className={cls} key={filter.id} onClick={()=> toggleFilters(filter.id)}>{filter.text}</div>)
            })}
          </div>
        </div>

        <button className={resetCls} onClick={resetFilters}>Reset Filter</button>
      </div>

      <div className={styles.hexagons}>
        {caseStudies.map(item => {
          let disabled = true;
          if (filtersOn) {
            item.filterTypes.forEach(filterType => {
              if (filters.has(filterType)) {
                disabled = false;
              }
            })
          } else {
            disabled = false;
          }

          const itemClass = cn(styles.hexagons__item, styles['hexagons__item__' + item.num], {
            [styles.disabled]: disabled
          })
          return (
            <div className={itemClass} key={item.num}>
                <Hexagon onShapeClick={() => !disabled && goToDetail(item.slug)} strokeOnly className={styles.shape} />
                <img className={styles.image} src={item.image} alt=""/>
              <p>{item.title}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withRouter(CaseStudies)