import styles from "./TextBox.module.scss";
import cn from "classnames";

export default function TextBox({children, className, isLarge}) {
  const boxClass = cn(styles.textBox, className, {
    [styles.large]: isLarge
  })
  return (
    <div className={boxClass}>
      {children}
    </div>
  )
}