import Hexagon from "../../components/Hexagon/Hexagon";
import styles from "./HexgonFlipper.module.scss";
import cn from "classnames";

export default function HexagonFlipper({icon, text, hiddenText, num, activeNum, onItemClick, bigIcon = false}) {
  const isActive = num === activeNum
  const itemClass = cn(styles.item, {
    [styles.showHidden]: isActive,
    [styles.bigIcon]: bigIcon
  })

  const handleClick = () => {
    onItemClick(isActive ? null : num);
  }

  return (
    <div className={itemClass}>
      <Hexagon number={num} onShapeClick={handleClick}/>
      <div className={styles.inner}>
        <div>
          <img src={icon} className={styles.icon} alt=""/>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      <div className={styles.hiddenItem}>
        <Hexagon whiteFillOnly noPointerEvents />
        <div className={styles.hiddenText}>{hiddenText}</div>
        <span className={styles.close}>Close</span>
      </div>
    </div>
  )
}