export const requestFullScreen = (elem) => {
  console.log(elem);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* FireFox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

export const isFullscreen = () => {
  return  !!(document.isFullScreen
      || document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement
  )
}

export const getWindowScale =() => {
    let screen = document.getElementById("screen"),
        safeArea = document.getElementById("safe-area")

    if (!screen || !safeArea) return 1;

    let contentWidth = screen.offsetWidth, // width of #screen
        contentHeight = safeArea.offsetHeight, // height of #screen
        viewportWidth = window.innerWidth, // 100vw
        viewportHeight = window.innerHeight; // 100vh

    return Math.min(viewportHeight / contentHeight, viewportWidth / contentWidth);
}
