import cn from "classnames"
import styles from "./Popup.module.scss"

/* interface props {
  show: boolean
  alignBox: 'bottom' | 'top' | 'middle'
  sideOfTarget: 'left' | 'right'
  size: 'base' | 'large' | 'medium'
  icon?: string
  title?: string
  text: string
  buttonText: string
  onButtonClick?: () => void
}
 */

export default function Popup({
  show = false,
  alignBox = "bottom",
  sideOfTarget = "right",
  size="base",
  buttonText = "Close",
  icon,
  title,
  text,
  onBtnClick
}) {
  const wrapperClass = cn(styles.wrapper, {
    [styles.show]: show,
    [styles.rightOfTarget]: sideOfTarget === 'right',
      [styles.leftOfTarget]: sideOfTarget === 'left'
  })

  const lineClass = cn(styles.line, {
    [styles.rightOfTarget]: sideOfTarget === 'right',
    [styles.leftOfTarget]: sideOfTarget === 'left',
    [styles.show]: show
  })

  const boxClass = cn(styles.box, {
    [styles.show]: show,
    [styles.alignBottom]: alignBox === 'bottom',
    [styles.alignTop]: alignBox === 'top',
    [styles.alignMiddle]: alignBox === 'middle',
    [styles.isLarge]: size === 'large',
    [styles.isMedium]: size === 'medium',
    [styles.rightOfTarget]: sideOfTarget === 'right',
    [styles.leftOfTarget]: sideOfTarget === 'left'
  })

  const btnClass = cn(styles.button, {
    [styles.show]: show
  })

  return (
   <div className={wrapperClass} onClick={(event) => event.stopPropagation()}>
     <div className={lineClass}/>
      <div className={boxClass}>
        { icon && <img src={icon} className={styles.icon} alt=""/>}
        { title && <div className={styles.title}>{title}</div>}
        <div className={styles.text}>{text}</div>
        <button className={btnClass} onClick={(event)=>{
          event.stopPropagation();
          if (onBtnClick) onBtnClick();
        }}>{buttonText}</button>
      </div>
   </div>
  )
}
