import styles from "./CaseStudiesDetail.module.scss"
import {caseStudies, features} from "../../data/caseStudies";
import {useParams} from "react-router-dom";
import TextBox from "../../components/TextBox/TextBox";
import cn from "classnames";
import HexagonShortButton from "../../components/HexagonShortButton/HexagonShortButton";
import {useRef, useState} from "react";
import Popup from "../../components/Popup/Popup";
import {requestFullScreen, isFullscreen} from "../../utils/helpers";
import BigPlayButton from "../../components/BigPlayButton/BigPlayButton";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export default function CaseStudiesDetail() {
  const { slug } = useParams();
  const caseStudy = caseStudies.find(item => item.slug === slug);

  const [activePopup, setActivePopup] = useState(null);

  const [videoMuted, setVideoMuted] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const video = useRef(null)

  const playVideo = () => {
    video.current.play()
  }

  const pauseVideo = () => {
    video.current.pause();
  }

  const rewindVideo = () => {
    video.current.currentTime -= 15
  }

  const forwardVideo = () => {
    video.current.currentTime += 15
  }

  const onVideoEnded = () => {
    video.current.currentTime = 0;
  }

  const onPageClickCatch = (event) => {
    setActivePopup(null);
  }

  const onVideoClick = (event) => {
    if (isFullscreen() || !videoCanPlay) {
      return
    }
    videoPlaying ? pauseVideo() : playVideo()
  }

  return(
    <div className="page" onClick={onPageClickCatch}>
      <TextBox className={styles.textBox}>
        <h2>{caseStudy.title}</h2>
        <p>{caseStudy.intro}</p>
      </TextBox>
      <div className={styles.outcomes}>{caseStudy.outcomes}</div>

      <div className={styles.features}>
        {features.map(feature => {

          const caseStudyFeature = caseStudy.features[feature.type];
          const cls = cn(styles.features__item, {
            [styles.disabled]: !caseStudyFeature
          })
          const handleClick = (event) => {
            if (!caseStudy) {
              return
            }
            event.stopPropagation()
            setActivePopup(feature.type)
          }

          return (
            <div className={cls} onClick={handleClick} key={feature.id}>
              <img src={feature.image} alt=""/>
              {caseStudyFeature
                ?  <Popup
                    show={activePopup === feature.type}
                    alignBox={feature.id < 3 ? "top" : "bottom"}
                    text={caseStudyFeature}
                    onBtnClick={()=> setActivePopup(null)}
                  />
                : null}
            </div>
          )
        })}
      </div>

      <div className={styles.videoContainer}>
        {/*NOTE the video source has #t=001 added to url to make video thumbnails load on iOS */}
        <video
          onClick={onVideoClick}
          ref={video}
          onEnded={onVideoEnded}
          onPlay={()=> setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          muted={videoMuted}
          onCanPlay={() => setVideoCanPlay(true)}
          preload="auto">
          <source src={caseStudy.video + "#t=0.001"} type="video/mp4"/>
            Sorry, your browser doesn't support embedded videos.
        </video>


        <LoadingSpinner show={!videoCanPlay}/>

        <BigPlayButton show={videoCanPlay && !videoPlaying}/>

        <div className={cn(styles.videoButton, {
          [styles.videoButton__disabled]: !videoCanPlay
        })}>
          <HexagonShortButton  flexInner paddingInner >
            <img src={require("assets/img/video-rewind.svg").default} onClick={rewindVideo} alt=""/>
            { videoPlaying
              ? <img src={require("assets/img/video-pause.svg").default} onClick={pauseVideo} alt=""/>
              : <img src={require("assets/img/video-play.svg").default} onClick={playVideo} alt=""/>
            }
            <img src={require("assets/img/video-forward.svg").default} onClick={forwardVideo} alt=""/>
            { videoMuted
              ?  <img src={require("assets/img/video-sound-off.svg").default} onClick={() => setVideoMuted(false)} alt=""/>
              : <img src={require("assets/img/video-sound-on.svg").default} onClick={() => setVideoMuted(true)} alt=""/>
            }
            <img src={require("assets/img/video-full-screen.svg").default} onClick={() => requestFullScreen(video.current)} alt=""/>

          </HexagonShortButton>
        </div>

      </div>
    </div>
  )
}